import CanvasView from '~/components/canvas-view/canvas-view'
import styles from '~/components/validation-view/validation-view.module.scss'
import { Component, Show, createEffect, createSignal } from 'solid-js'
import { ValidationViewProps } from '~/components/validation-view/validation-view.interfaces'
import { chapter, getChapterData, setChapter } from '~/services/current-document/chapter'
import { getTranslations } from '~/services/database/translations'
import { useNavigate } from '@solidjs/router'
import { createInitialTypesetTexts } from '~/services/current-document/loading/initial-typeset-texts'
import { getTypesetTexts } from '~/services/database/typeset-texts'
import { loadTypesetTexts } from '~/services/current-document/loading/typeset-texts'
import { loadTranslations } from '~/services/current-document/loading/translations'
import { DocumentProps } from '~/editor/document/document.interfaces'
import Document from '~/editor/document/document'
import { getComments } from '~/services/database/comments'
import SidebarHeader from '~/components/sidebar-header/sidebar-header'
import ExportModal from '~/components/export-modal/export-modal'
import ValidateChapterStep from '~/components/validate-chapter-step/validate-chapter-step'
import TypeProofreadingList from '~/components/type-proofreading-list/type-proofreading-list'
import { translations } from '~/services/current-document/translations'
import { typesetTexts } from '~/services/current-document/typeset-texts'
import { setLoadingState } from '~/services/loading/loading'

const ValidationView: Component<ValidationViewProps> = ({ chapterId }) => {
  const [isTranslationViewLoading, setTranslationViewLoading] = createSignal(true)
  const [isValidationViewLoading, setValidationViewLoading] = createSignal(true)
  const [showExportModal, setExportModal] = createSignal(false)
  const [translationDocument, setTranslationDocument] = createSignal<Document>()
  const [typesettingDocument, setTypesettingDocument] = createSignal<Document>()
  const navigate = useNavigate()

  // Scroll Sync
  createEffect(() => {
    const doc1 = typesettingDocument()
    const doc2 = translationDocument()
    if(doc1 && doc2){
      doc1.onScrollHandler = (top) => doc2.scrollTo(top)
      doc2.onScrollHandler = (top) => doc1.scrollTo(top)
    }
  })

  // Link Translations <> TypesettingTexts when both are ready
  createEffect(() => {
    if (!isTranslationViewLoading() && !isValidationViewLoading()){
      typesetTexts().forEach(typesetText => {
        typesetText.translation = translations().find(translation => translation.id === typesetText.translationId) ?? null
        if(typesetText.translation && !typesetText.translation.typesetText){
          typesetText.translation.typesetText = typesetText
        }
      })
    }
  })

  const onTypesettingDocumentReady = async (props: DocumentProps) => {
    const typesettingDocument = new Document(props)
    setTypesettingDocument(typesettingDocument)
    const chapterData = await getChapterData(chapterId)
    setChapter(chapterData)
    const translationsData = await getTranslations(chapterId)
    const typesetTextsData = await getTypesetTexts(chapterId)

    const cleanPagesExist = chapterData.pages.clean.length > 0
    const typesetTextsExist = typesetTextsData.length > 0
    if (!cleanPagesExist) {
      navigate(`/series/chapter/${chapterId}/upload`)
    } else {
      if (typesetTextsExist) {
        loadTypesetTexts({
          currentDocument: typesettingDocument,
          chapterData,
          typesetTextsData,
          setIsLoading: setValidationViewLoading
        })
      } else {
        createInitialTypesetTexts({
          currentDocument: typesettingDocument,
          chapterData,
          translationsData,
          setIsLoading: setValidationViewLoading
        })
      }
    }
  }

  const onTranslationDocumentReady = async (props: DocumentProps) => {
    const translationDocument = new Document(props)
    setTranslationDocument(translationDocument)
    const chapterData = await getChapterData(chapterId)
    setChapter(chapterData)
    const translationsData = await getTranslations(chapterId)
    const commentsData = await getComments(chapterId)

    loadTranslations({
      currentDocument: translationDocument,
      chapterData,
      translationsData,
      commentsData,
      setIsLoading: setTranslationViewLoading
    })
  }

  createEffect(() => {
    const isLoading = isTranslationViewLoading() || isValidationViewLoading()
    setLoadingState({
      loading: isLoading
    })
  })
  
  return (
    <div class={styles.validationView}>
      <CanvasView
        settings={{
          mode: 'translation',
          readOnly: true
        }}
        onReady={onTranslationDocumentReady}
        invisible={true}
      />
      <CanvasView
        settings={{
          mode: 'typesetting',
          readOnly: true
        }}
        onReady={onTypesettingDocumentReady}
      />
      <div class={styles.sideView}>
        <Show when={chapter()}>
          {chapter => (
            <SidebarHeader
              chapter={chapter()}
              mode='validation'
            />
          )}
        </Show>
        <ExportModal
          open={showExportModal()}
          setOpen={setExportModal}
          chapterId={chapterId}
        />
        <TypeProofreadingList />
        <ValidateChapterStep
          mode='validation'
        />
      </div>
    </div>
  )
}

export default ValidationView