import { useParams } from '@solidjs/router'
import ValidationView from '~/components/validation-view/validation-view'
import { ValidationParams } from '~/types/routes/series/validation'

export default function Validation() {
  const { id } = useParams<ValidationParams>()
  return (
    <>
      <ValidationView chapterId={id} />
    </>
  )
}
